import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

interface Props {
  description?: string
  lang?: string
  meta?: JSX.IntrinsicElements['meta'][]
  title?: string
  ogType?: string
  image?: string
  location?: Location
}

const SEO: FC<Props> = ({
  description,
  lang,
  meta,
  title,
  ogType,
  image,
  location
}) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          siteUrl
          title
          description
          image
          company {
            name
            logo
            siteUrl
          }
        }
      }
    }
  `)
  const siteUrl = data.site.siteMetadata.siteUrl
  const metaDescription =
    description === ''
      ? description
      : description || data.site.siteMetadata.description
  const pageTitle = title
    ? `${title} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title
  const metaItems = [
    // {
    //   name: `robots`,
    //   content: `noindex,nofollow`
    // },
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: 'og:url',
      content: location ? `${siteUrl}${location.pathname}` : siteUrl
    },
    {
      property: `og:title`,
      content: pageTitle
    },
    {
      property: `og:description`,
      content: metaDescription
    },
    {
      property: `og:type`,
      content: ogType || `article`
    },
    {
      property: `og:image`,
      content: image || `${siteUrl}${data.site.siteMetadata.image}`
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`
    },
    {
      name: `twitter:creator`,
      content: data.site.siteMetadata.twitterCreator
    },
    {
      name: `twitter:title`,
      content: pageTitle
    },
    {
      name: `twitter:description`,
      content: metaDescription
    },
    {
      name: `twitter:image`,
      content: image || `${siteUrl}${data.site.siteMetadata.image}`
    }
  ] as JSX.IntrinsicElements['meta'][]
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title || data.site.siteMetadata.title}
      titleTemplate={title ? `%s | ${data.site.siteMetadata.title}` : `%s`}
      link={[
        location
          ? {
              rel: `canonical`,
              href: `${siteUrl}${location.pathname}`
            }
          : {}
      ]}
      meta={(() => {
        if (meta) {
          return metaItems.concat(meta)
        }
        return metaItems
      })()}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`
}

export default SEO
