import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import SEO from '@/components/head/SEO'
import { mq, rem } from '@/utils/helpers'
import { breakpoints } from '@/utils/preset'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import ServiceLogo from '@/components/snippets/ServiceLogo'
import Typography from '@/components/typography'

interface Props {
  [key: string]: any
}

const redirectToLine = () => {
  const w: any = window
  if (!w.dataLayer) {
    requestAnimationFrame(redirectToLine)
    return
  }
  window.location.href = 'https://lin.ee/1fRZpj1'
}

const NotFound: FC<Props> = () => {
  const [flag] = useState(false)
  useEffect(() => {
    redirectToLine()
  }, [flag])
  return (
    <Root>
      <SEO title="LINEを開いています" description="" />
      <Section color="black.0">
        <Container type={3}>
          <Logo>
            <ServiceLogo />
          </Logo>
          <Head>
            <Title variant="h3" component="h1" lang="en">
              LINEを開いています
            </Title>
          </Head>
          <Main>
            <Content>
              <p>まもなくLINEアプリを起動します。数秒お待ちください。</p>
            </Content>
          </Main>
        </Container>
      </Section>
    </Root>
  )
}

const Root = styled.div``

const Logo = styled.div`
  display: block;
  text-align: center;

  svg {
    width: ${rem(180)};
    height: ${rem(60.5)};

    @media ${mq.and(breakpoints.sm)} {
      width: ${rem(160)};
      height: ${rem(53.7)};
    }
  }
`

const Head = styled.div`
  margin-top: ${rem(32)};
`

const Title = styled(Typography)`
  text-align: center;
`

const Main = styled.div`
  margin-top: ${rem(24)};
`

const Content = styled.div`
  p {
    text-align: center;
    font-size: ${rem(14)};
    line-height: 1.8;
  }
`

export default NotFound
