import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { color, Theme } from '@/utils/themes'
import ThemeContext from '@/context/Theme'

interface Props {
  color: string
  stripe?: boolean
  [key: string]: any
}
interface StyleProps extends Props {
  theme: Theme
}

const Section: FC<Props> = ({ children, ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...others}>
      {children}
    </Root>
  )
}

const Root = styled.div<StyleProps>`
  position: relative;
  background-color: ${color()};
  padding: ${rem(80)} 0;
  ${(props) =>
    props.stripe &&
    css`
      background-image: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05) ${rem(4)},
        transparent ${rem(4)},
        transparent ${rem(20)}
      );
    `}
  @media ${mq.and(breakpoints.md)} {
    padding: ${rem(64)} 0;
  }
  @media ${mq.and(breakpoints.sm)} {
    padding: ${rem(48)} 0;
  }
`

export default Section
